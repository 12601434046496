import axios from "axios";

const backendUri = process.env.REACT_APP_BACKEND_URI;

const retrieveFileAPI = async (snippet) => {
  try {
    const response = await axios.get(
      `${backendUri}/handle-file/file/${snippet}`,
      {
        responseType: "blob",
        headers: {
          incrementViewCount: true,
        },
      }
    );
    if (!response || !response.data) {
      throw new Error("Invalid response format");
    }
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        return {
          error: "API Error",
          message: error.response.data.message || "Error fetching snippet",
          status: error.response.status,
        };
      } else if (error.request) {
        return {
          error: "Network Error",
          message: "No response received from server",
          status: 0,
        };
      }
    }
    return {
      error: "Request Error",
      message: error.message || "Error fetching snippet",
      status: 500,
    };
  }
};

const uploadFileToServerAPI = async (fileData) => {
  try {
    const formData = new FormData();
    formData.append("myFile", fileData);
    const response = await axios.post(
      `${backendUri}/handle-file/upload-image`,
      formData,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
    if (response.status !== 201 || !response.data.key) {
      throw new Error("Invalid response format");
    }
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        return {
          error: "API Error",
          message: error.response.data.message || "Error uploading file",
          status: error.response.status,
        };
      } else if (error.request) {
        return {
          error: "Network Error",
          message: "No response received from server",
          status: 0,
        };
      }
    }
    return {
      error: "Request Error",
      message: error.message || "Error uploading file",
      status: 500,
    };
  }
};

const fileApi = { retrieveFileAPI, uploadFileToServerAPI };

export default fileApi;
