import React from "react";

export const ShowFile = ({ imageUrl, fileName, fileType }) => {
  return (
    <div className="flex justify-center">
      {fileType.startsWith("image/") ? (
        <img
          className="h-56 w-56 object-cover shadow-lg rounded-lg"
          src={imageUrl}
          alt="File Preview"
        />
      ) : (
        <p className="text-lg font-semibold">{fileName}</p>
      )}
    </div>
  );
};
