import React from "react";
import { Home } from "./components/Home";
// import Dock from "./components/Dock";

const App = () => {
  return (
    <>
      <Home />
      {/* <Dock /> */}
    </>
  );
};

export default App;
