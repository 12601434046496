import React, { useState } from "react";
import { FaGlobe } from "react-icons/fa";

const SocialLinks = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <div className="bg-transparent p-8 max-w-sm mx-auto flex justify-center items-center space-x-6">
      <a
        href="https://www.linkedin.com/in/itsjatin135s/"
        target="_blank"
        rel="noopener noreferrer"
        className="flex items-center border-2 border-blue-500 text-blue-500 py-2 px-4 rounded-full transition-all duration-300 hover:bg-blue-500 hover:text-white hover:shadow-lg"
      >
        {!isLoaded && (
          <div className="w-8 h-8 rounded-full border-white border-2 bg-gray-200 animate-pulse"></div>
        )}

        <img
          src="https://github.com/itsjatin135s/portfolioCDN/blob/main/ShareSnippetAvatar.png?raw=true"
          alt="Avatar"
          className={`w-8 h-8 rounded-full border-white border-2 transition-opacity duration-300 ${
            isLoaded ? "opacity-100" : "opacity-0"
          }`}
          onLoad={() => setIsLoaded(true)}
        />

        <span className={`text-lg font-medium ml-3 ${isLoaded && "mr-8"}`}>
          LinkedIn
        </span>
      </a>
      <a
        href="https://jatinbagri.com"
        target="_blank"
        rel="noopener noreferrer"
        className="flex items-center space-x-3 border-2 border-black text-black py-2 px-4 rounded-full transition-all duration-300 hover:bg-black hover:text-white hover:shadow-lg"
      >
        <FaGlobe className="text-2xl ml-2" />
        <span className="text-lg font-medium">Portfolio</span>
      </a>
    </div>
  );
};

export default SocialLinks;
