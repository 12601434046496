export const LoadingAnimation = () => {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      fill="#fff"
      className="animate-spin"
    >
      <circle
        cx="50"
        cy="50"
        r="45"
        stroke="#fff"
        strokeWidth="10"
        fill="none"
      />
      <circle
        cx="50"
        cy="50"
        r="35"
        stroke="#fff"
        strokeWidth="10"
        fill="none"
        strokeDasharray="219.91"
        strokeDashoffset="219.91"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          from="0 50 50"
          to="360 50 50"
          dur="1.5s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="stroke-dashoffset"
          from="219.91"
          to="0"
          dur="1.5s"
          repeatCount="indefinite"
        />
      </circle>
    </svg>
  );
};
