import React, { useState, useEffect } from "react";
import Editor from "react-simple-code-editor";
import Prism from "prismjs";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-markup";
import "prismjs/components/prism-python";
import "prismjs/components/prism-css";
import "prismjs/themes/prism-tomorrow.css";

// assets
import copyClipboardIcon from "../../src/static/svg/CopyClipboard.svg";
import checkMark from "../../src/static/svg/CheckMark.svg";
import { LoadingAnimation } from "../static/svgComponents/LoadingAnimation.js";

import DialogBox from "../components/ResponseAlert";
import UploadFile from "../components/UploadFile";
import fileHandlerHelpers from "../helper/fileshandler.js";
import { ShowFile } from "../components/ShowFile";
import textApi from "../api/textApi.js";
import fileApi from "../api/fileApi.js";
import snippetValidators from "../helper/snippetChecks.js";
import SnippetViewCounter from "./SnippetViewCounter.js";
import SocialLinks from "./SocialLink.js";

export const Home = () => {
  const [passcode, setPasscode] = useState("");
  const [timer, setTimer] = useState(200);
  const [uploading, setUploading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [isCopy, setIsCopy] = useState(true);
  const [hideFile, setHideFile] = useState(false);
  const [isDownloadSnippet, setIsDownloadSnippet] = useState(false);

  const [imageUrl, setImageUrl] = useState(false);
  const [fileName, setFileName] = useState(false);
  const [fileType, setFileType] = useState("");
  const [fileResponse, setFileResponse] = useState("");

  const [snippetMetaData, setSnippetMetaData] = useState({
    snippet: "",
    isCodeSnippet: false,
    language: "javascript",
  });

  const [dialog, setDialog] = useState({
    isOpen: false,
    type: "",
    message: "",
  });

  const backendUri = process.env.REACT_APP_BACKEND_URI;

  useEffect(() => {
    if (timer > 0) {
      const intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      return () => clearInterval(intervalId);
    } else {
      setPasscode("");
    }
  }, [timer]);

  const handleRetrieveSnippet = async () => {
    setDownloading(true);
    try {
      if (!snippetMetaData.snippet) {
        // add a check snippet method in if condition which check if there is snippet and also check if snippet format is correct
        throw new Error("Please enter a snippet code");
      }
      if (snippetMetaData?.snippet.slice(0, 1) === "T") {
        const response = await textApi.retrieveText(snippetMetaData.snippet);
        if (response.error) {
          throw new Error(response.message);
        }
        const data = response.data;
        setSnippetMetaData((prevState) => ({
          ...prevState,
          snippet: data.text,
          isCodeSnippet: data.isCodeSnippet,
          language: data.language,
        }));
      } else if (snippetMetaData?.snippet.slice(0, 1) === "F") {
        const response = await fileApi.retrieveFileAPI(snippetMetaData.snippet);
        if (response.error) {
          throw new Error(response.message);
        }
        setFileResponse(response);
        setFileName(await fileHandlerHelpers.getFileName(response));
        setFileType(response.headers.get("content-type"));
        if (response.headers.get("content-type").startsWith("image/")) {
          setImageUrl(
            `${backendUri}/handle-file/file/${snippetMetaData.snippet}`
          );
        }
        setIsDownloadSnippet(true);
      }
    } catch (err) {
      setDialog({
        isOpen: true,
        type: "error",
        message: err.message,
      });
    } finally {
      setDownloading(false);
      setHideFile(true);
      return;
    }
  };

  const handleUploadText = async () => {
    try {
      setUploading(true);
      const checkSnippet = snippetValidators.snippetCheck(
        snippetMetaData.snippet
      );
      if (checkSnippet) {
        setDialog(checkSnippet);
        return;
      }
      const response = await textApi.uploadText(snippetMetaData);
      if (response.status === 201) {
        updatePasscode(response.data.key);
      } else {
        throw new Error(response.message);
      }
    } catch (err) {
      setDialog({
        isOpen: true,
        type: "error",
        message: err.message,
      });
    } finally {
      setUploading(false);
    }
  };

  const handleShowFile = () => {
    setHideFile((prev) => !prev);
  };

  const handelIsCodeSnipper = () => {
    setSnippetMetaData((prevState) => ({
      ...prevState,
      isCodeSnippet: !prevState.isCodeSnippet,
    }));
  };

  const handleSnippetChange = (newValue) => {
    setSnippetMetaData((prevState) => ({
      ...prevState,
      snippet: newValue,
    }));
  };

  const handleLanguageChange = (event) => {
    setSnippetMetaData((prevState) => ({
      ...prevState,
      language: event.target.value,
    }));
  };

  const highlightCode = (code) => {
    return Prism.highlight(
      code,
      Prism.languages[snippetMetaData.language],
      snippetMetaData.language
    );
  };

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(passcode)
      .then(() => {
        setIsCopy(false);
        setTimeout(() => {
          setIsCopy(true);
        }, 3000);
      })
      .catch((err) => {
        console.error("Could not copy text: ", err);
      });
  };

  const updatePasscode = (key) => {
    setPasscode(key);
    setTimer(200);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-br to-gray-300 p-4 mb-14">
      <h1 className="text-5xl font-extrabold mb-8 text-gray-800 bg-clip-text text-transparent bg-gradient-to-r from-teal-500 to-blue-500">
        Share Snippet
      </h1>
      <div className="bg-white p-8 rounded-3xl shadow-lg w-full max-w-lg border border-gray-200">
        <div className="flex items-center mb-6">
          <input
            type="checkbox"
            id="isCodeSnippet"
            className="hidden"
            checked={snippetMetaData.isCodeSnippet}
            onChange={handelIsCodeSnipper}
          />
          <label
            htmlFor="isCodeSnippet"
            className={`w-6 h-6 rounded-full border-2 border-gray-700 cursor-pointer flex justify-center items-center ${
              snippetMetaData.isCodeSnippet ? "bg-gray-700" : "bg-transparent"
            }`}
          >
            {snippetMetaData.isCodeSnippet && (
              <span className="w-3 h-3 bg-white rounded-full inline-block"></span>
            )}
          </label>
          <span className="ml-3 mr-2">Is Code Snippet</span>
          {snippetMetaData.isCodeSnippet && (
            <select
              value={snippetMetaData.language}
              onChange={handleLanguageChange}
              className="p-2 border border-gray-300 rounded-xl text-gray-700"
            >
              <option value="javascript">JavaScript</option>
              <option value="python">Python</option>
              <option value="markup">HTML/XML</option>
              <option value="css">CSS</option>
            </select>
          )}
        </div>
        {snippetMetaData.isCodeSnippet ? (
          <Editor
            value={snippetMetaData.snippet}
            onValueChange={handleSnippetChange}
            highlight={highlightCode}
            padding={10}
            className="border border-gray-300 rounded-xl mb-6 text-gray-700 bg-gray-100 font-mono h-40 overflow-auto"
            style={{
              fontFamily: '"Fira code", "Fira Mono", monospace',
              fontSize: 14,
            }}
          />
        ) : (
          <input
            type="text"
            placeholder="Enter your snippet or retrieve via passcode"
            className="w-full p-4 border border-gray-300 rounded-xl mb-6 text-gray-700 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-teal-500 transition duration-300 ease-in-out"
            value={snippetMetaData.snippet}
            onChange={(e) => handleSnippetChange(e.target.value)}
          />
        )}
        <div className="flex flex-col gap-4">
          {passcode ? (
            <div className="ml-4">
              <div className="flex items-center space-x-2">
                <p className="text-gray-900 font-medium text-md">
                  Key: {passcode}
                </p>
                <SnippetViewCounter snippetKey={passcode} />
                <button
                  onClick={copyToClipboard}
                  className="text-white px-2 py-1 rounded-lg hover:bg-gray-100 hover:shadow-lg transition duration-300 ease-in-out"
                >
                  {isCopy ? (
                    <img
                      src={copyClipboardIcon}
                      alt="Copy"
                      className="w-5 h-5"
                    />
                  ) : (
                    <img src={checkMark} alt="Copied" className="w-5 h-5" />
                  )}
                </button>
              </div>
              <p className="text-red-900 font-medium text-md">
                Expires In : {timer}
              </p>
            </div>
          ) : (
            <></>
          )}
          <button
            onClick={handleRetrieveSnippet}
            className="bg-gradient-to-r from-teal-400 to-teal-600 text-white px-6 py-3 rounded-xl shadow-md hover:from-teal-500 hover:to-teal-700 transition duration-300 ease-in-out"
          >
            {!downloading ? (
              "Retrieve Snippet"
            ) : (
              <div className="flex items-center justify-center">
                <LoadingAnimation />
                <span className="ml-2">Fetching Your Snippet...</span>
              </div>
            )}
          </button>
          <button
            onClick={handleUploadText}
            className="bg-gradient-to-r from-blue-400 to-blue-600 text-white px-6 py-3 rounded-xl shadow-md hover:from-blue-500 hover:to-blue-700 transition duration-300 ease-in-out"
          >
            {!uploading ? (
              "Upload Snippet"
            ) : (
              <div className="flex items-center justify-center">
                <LoadingAnimation />{" "}
                <span className="ml-2"> Broadcasting Snippet...</span>
              </div>
            )}
          </button>
          <div className="flex justify-center items-center w-full mx-auto">
            {isDownloadSnippet && (
              <button
                onClick={() =>
                  fileHandlerHelpers.retrieveFileFromServer(
                    fileResponse,
                    fileName
                  )
                }
                className="border-black border-2 text-black font-semibold py-2 px-4 w-36 rounded-lg shadow-md hover:bg-black hover:text-white hover:shadow-lg transition-all"
              >
                Download File
              </button>
            )}
          </div>
          {fileType && (
            <ShowFile
              imageUrl={imageUrl}
              fileType={fileType}
              fileName={fileName}
            />
          )}
          <UploadFile
            updatePasscode={updatePasscode}
            hideFile={hideFile}
            handleShowFile={handleShowFile}
          />
        </div>
      </div>
      <SocialLinks />
      {dialog.isOpen && (
        <DialogBox
          type={dialog.type}
          message={dialog.message}
          onClose={() => setDialog({ isOpen: false })}
        />
      )}
    </div>
  );
};
