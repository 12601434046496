import React, { useState, useEffect } from "react";

import snippetValidators from "../helper/snippetChecks";

const backendSocketUri = process.env.REACT_APP_BACKEND_SOCKET_URI;

const SnippetViewCounter = ({ snippetKey }) => {
  const [viewCount, setViewCount] = useState(0);

  useEffect(() => {
    const socket = new WebSocket(backendSocketUri);

    socket.onopen = () => {
      socket.send(JSON.stringify({ snippetKey }));
    };

    socket.onmessage = (event) => {
      const data = snippetValidators.checkSnippetFromSocket(event.data)
        ? event.data
        : JSON.parse(event.data);
      if (data.key === snippetKey) {
        setViewCount(data.views);
      } else {
        setViewCount(0);
      }
    };

    socket.onclose = () => {
      if (process.env.NODE_ENV === "development") {
        console.log("WebSocket connection closed.");
      }
    };
    return () => {
      socket.close();
    };
  }, [snippetKey]);

  return (
    <div className="snippet-view-counter">
      <p className="text-gray-900 font-medium text-md">Views: {viewCount}</p>
    </div>
  );
};

export default SnippetViewCounter;
