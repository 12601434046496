import axios from "axios";

const backendUri = process.env.REACT_APP_BACKEND_URI;

const retrieveText = async (snippet) => {
  try {
    const response = await axios.get(`${backendUri}/texts/${snippet}`);
    if (!response.data || !response.data.text) {
      throw new Error("Invalid response format");
    }
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        return {
          error: "API Error",
          message: error.response.data.message || "Error fetching snippet",
          status: error.response.status,
        };
      } else if (error.request) {
        return {
          error: "Network Error",
          message: "No response received from server",
          status: 0,
        };
      }
    }
    return {
      error: "Request Error",
      message: error.message || "Error fetching snippet",
      status: 500,
    };
  }
};

const uploadText = async (snippetMetaData) => {
  try {
    const response = await axios.post(`${backendUri}/texts`, snippetMetaData);
    if (!response.data || !response.data.key) {
      throw new Error("Invalid response format");
    }
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        return {
          error: "API Error",
          message: error.response.data.message || "Error uploading snippet",
          status: error.response.status,
        };
      } else if (error.request) {
        return {
          error: "Network Error",
          message: "Unable to connect to server",
          status: 0,
        };
      }
    }
    return {
      error: "Request Error",
      message: "Error uploading snippet",
      status: 500,
    };
  }
};

const textApi = { retrieveText, uploadText };

export default textApi;
