const getFileName = (response) => {
  const contentDisposition = response.headers.get("content-disposition");
  let filename = "snippet-file";

  if (contentDisposition && contentDisposition.includes("attachment")) {
    const filenameMatch = contentDisposition.match(/filename="(.+)"/);

    if (filenameMatch) {
      return filenameMatch[1];
    }
  }
  return filename;
};

const retrieveFileFromServer = (blob, fileName) => {
  try {
    const url = window.URL.createObjectURL(blob.data);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  } catch (error) {
    console.error("Error downloading the file:", error);
  }
};

const fileHandlerHelpers = { getFileName, retrieveFileFromServer };

export default fileHandlerHelpers;
