import React, { useState } from "react";

import DialogBox from "./ResponseAlert";
import { ShowFile } from "./ShowFile";
import fileApi from "../api/fileApi";
import { LoadingAnimation } from "../static/svgComponents/LoadingAnimation.js";

function UploadFile({ updatePasscode, handleShowFile }) {
  const [file, setFile] = useState(null);
  const [error, setError] = useState("");
  const [fileType, setFileType] = useState("");
  const [fileData, setFileData] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [dialog, setDialog] = useState({
    isOpen: false,
    type: "",
    message: "",
  });

  const handleChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      if (selectedFile.size > 2 * 1024 * 1024) {
        setError("File size exceeds 2 MB. Please select a smaller file.");
        setFile(null);
        return;
      }

      const fileType = selectedFile.type;
      setFileType(fileType);
      if (
        fileType.startsWith("image/") ||
        fileType.startsWith("application/")
      ) {
        setFile(URL.createObjectURL(selectedFile));
        setFileData(selectedFile);
        handleShowFile(false);
        setError("");
      } else {
        setFile(selectedFile.name);
        setError("");
      }
    }
  };

  const UploadFileToServer = async () => {
    try {
      setUploading(true);
      if (!fileType || !file) {
        throw new Error("Please add a file");
      }
      const uploadFile = await fileApi.uploadFileToServerAPI(fileData);
      if (uploadFile.error) {
        throw new Error(uploadFile.message);
      }
      updatePasscode(uploadFile.key);
    } catch (error) {
      setDialog({
        isOpen: true,
        type: "error",
        message: error.message,
      });
      return;
    } finally {
      setUploading(false);
    }
    return "Not OK";
  };

  return (
    <div className="flex flex-col items-center justify-center p-3 rounded-lg">
      <h2 className="text-3xl font-semibold mb-5 text-gray-900">
        <span className="border-b-4 border-blue-600 pb-1">Upload Files</span>
      </h2>
      <div className="flex items-center space-x-4 bg-white p-4 rounded-lg shadow-md mb-5 w-full">
        <input
          type="file"
          accept="image/*,.pdf,.xls,.xlsx"
          onChange={handleChange}
          className="file:mr-4 file:py-2 file:px-4 file:rounded-lg file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100 w-11/12 lg:w-72 transition-all"
        />
        <button
          onClick={UploadFileToServer}
          className="bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg shadow-md hover:bg-blue-700 transition-all"
        >
          {!uploading ? (
            "Upload"
          ) : (
            <div className="flex items-center justify-center">
              <LoadingAnimation /> <span className="ml-2"> Uploading...</span>
            </div>
          )}
        </button>
      </div>
      {dialog.isOpen && (
        <DialogBox
          type={dialog.type}
          message={dialog.message}
          onClose={() => setDialog({ isOpen: false })}
        />
      )}
      {error && <p className="text-red-500 mb-4">{error}</p>}
      {file && (
        <ShowFile
          imageUrl={file}
          fileType={fileType}
          fileName={fileData.name}
        />
      )}
    </div>
  );
}

export default UploadFile;
