const MAX_SNIPPET_LENGTH = 1000;

const snippetCheck = (snippet) => {
  if (!snippet) {
    return {
      isOpen: true,
      type: "error",
      message: "Please enter a snippet",
    };
  }

  if (snippet.length > MAX_SNIPPET_LENGTH) {
    return {
      isOpen: true,
      type: "error",
      message: `Snippet length must not exceed ${MAX_SNIPPET_LENGTH} characters`,
    };
  }
  return 0;
};

function checkSnippetFromSocket(message) {
  return message.startsWith("T") || message.startsWith("F");
}

const snippetValidators = { snippetCheck, checkSnippetFromSocket };

export default snippetValidators;
