import React from "react";

const DialogBox = ({ type, message, onClose }) => {
  const typeStyles = {
    success: {
      bgColor: "bg-green-100",
      textColor: "text-green-800",
      borderColor: "border-green-300",
      icon: "✔️",
    },
    warning: {
      bgColor: "bg-yellow-100",
      textColor: "text-yellow-800",
      borderColor: "border-yellow-300",
      icon: "⚠️",
    },
    error: {
      bgColor: "bg-red-50",
      textColor: "text-red-800",
      borderColor: "border-red-100",
      icon: "❌",
    },
  };

  const { bgColor, textColor, borderColor, icon } =
    typeStyles[type] || typeStyles.error;

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center bg-black bg-opacity-70`}
    >
      <div
        className={`w-full max-w-md p-4 ${bgColor} ${borderColor} border rounded-lg shadow-lg`}
      >
        <div className="flex items-center space-x-3 border-b border-gray-400">
          <span className="text-xs">{icon}</span>
          <span className={`text-lg font-bold ${textColor}`}>
            {type.charAt(0).toUpperCase() + type.slice(1)}
          </span>
        </div>
        <div className="mt-4 flex justify-between">
          <p className={`mt-3 text-lg ${textColor}`}>{message}</p>
          <button
            onClick={onClose}
            className="bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg shadow-md hover:bg-blue-700 transition-all"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default DialogBox;
